export const defaultModel = "gpt-4o";

export const availableModels = [
  {
    value: "gpt-3.5-turbo",
    label: "GPT-3.5-TURBO (Default ChatGPT)",
  },
  { value: "gpt-3.5-turbo-0301", label: "GPT-3.5-TURBO-0301" },
  { value: "gpt-4", label: "GPT-4 (Limited Beta)" },
  { value: "gpt-4-0314", label: "GPT-4-0314 (Limited Beta)" },
  { value: "gpt-4-32k", label: "GPT-4-32K (Limited Beta)" },
  {
    value: "gpt-4-32k-0314",
    label: "GPT-4-32K-0314 (Limited Beta)",
  },
];

export const writingCharacters = [
  {
    label: "喜剧演员",
    value:
      "通过讲笑话和幽默的故事来娱乐观众的表演者。",
  },
  {
    label: "生活教练",
    value:
      "专业人士，帮助个人识别和实现他们的个人和职业目标。",
  },
  {
    label: "职业顾问",
    value:
      "专业人士，帮助个人探索和选择职业，制定求职策略以及提高工作表现。",
  },
  {
    label: "营养师",
    value:
      "健康专业人士，专门研究营养及其对身体的影响。",
  },
  {
    label: "产品经理",
    value:
      "负责公司产品开发和营销的专业人士。",
  },
  {
    label: "私人教练",
    value:
      "健身专业人士，与个人合作制定个性化的运动计划，提高身体健康和健身水平。",
  },
  {
    label: "生活技巧达人",
    value:
      "使用非传统方法解决问题和提高日常生活生产力的人。",
  },
  {
    label: "旅行顾问",
    value:
      "专业人士，帮助个人计划和预订旅行安排。",
  },
  {
    label: "正念导师",
    value:
      "专业人士，帮助个人发展正念练习，减少压力，提高幸福感。",
  },
  {
    label: "财务顾问",
    value:
      "提供财务规划、投资策略和退休规划的指导和建议的专业人士。",
  },
  {
    label: "语言导师",
    value:
      "帮助个人学习和提高语言技能的教师。",
  },
  {
    label: "导游",
    value:
      "领导旅行团并提供有关当地景点和文化的信息的专业人士。",
  },
  {
    label: "营销专家",
    value:
      "开发和实施营销策略以推广产品和服务的专业人士。",
  },
  {
    label: "软件开发人员",
    value:
      "设计、开发和维护软件应用程序和系统的专业人士。",
  },
  {
    label: "约会教练",
    value:
      "帮助个人提高约会和恋爱技巧的专业人士。",
  },
  {
    label: "DIY专家",
    value:
      "熟练掌握在家里完成各种自主项目的人。",
  },
  {
    label: "记者",
    value:
      "调查和报道当前事件和新闻故事的专业人士。",
  },
  {
    label: "技术作家",
    value:
      "为各种受众撰写有关技术和相关主题的专业人士。",
  },
  {
    label: "职业厨师",
    value:
      "熟练的烹饪专业人士，负责准备餐点和管理厨房运营。",
  },
  {
    label: "专业销售人员",
    value:
      "向企业和消费者销售产品和服务的专业人士。",
  },
  {
    label: "初创科技企业律师",
    value:
      "专门为初创科技企业提供法律咨询和服务的法律专业人士。",
  },
  {
    label: "平面设计师",
    value:
      "设计视觉材料，如标志、宣传册和网站的专业人士。",
  },
  {
    label: "专家学者",
    value:
      "在特定学术领域从事研究和学术成果生产的专业人士。",
  },
  {
    label: "客户支持代表",
    value:
      "为对公司产品或服务有问题或疑问的客户提供协助和支持的专业人士。",
  },
  {
    label: "人力资源顾问",
    value:
      "为组织提供人力资源管理和战略方面的指导和建议的专业人士。",
  },
];

export const writingTones = [
  "自信的",
  "权威的",
  "随性的",
  "确信的",
  "轻蔑的",
  "交谈的",
  "圆滑的",
  "直截了当的",
  "雄辩的",
  "正式的",
  "友好的",
  "幽默的",
  "提供信息的",
  "鼓舞人心的",
  "紧张的",
  "易怒的",
  "说笑的",
  "有礼貌的",
  "讽刺的",
  "真诚的",
  "舒缓的",
  "严厉的",
  "有同情心的",
  "老练的",
  "机智的",
];

export const writingStyles = [
  "学术性的",
  "分析的",
  "辩论性的",
  "对话式的",
  "有创意的",
  "批判性的",
  "描述的",
  "解释性的",
  "提供信息的",
  "有指导性的",
  "调查性的",
  "新闻报道的",
  "比喻的",
  "叙述性的",
  "说服性的",
  "诗歌的",
  "讽刺的",
  "技术性的",
];

export const writingFormats = [
  { value: "回答尽可能简练", label: "简洁" },
  { value: "逐步思考", label: "逐步思考" },
  { value: "详细回答", label: "详细" },
  { value: "像我五岁一样解释", label: "像我五岁一样解释" },
];
